import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  Page,
  PageBack,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { t } from '@local/translations'
import { HelpSection } from './HelpSection'
import { FeinsWithoutBenefitsPanel } from './feins-without-benefits-panel'

export const GroupHealthPage: React.FunctionComponent = () => {
  return (
    <Page disableMaxWidth>
      <PageHeader>
        <Title>{t('group-health.title')}</Title>
        <PageBack as={Link} to='..' relative='path' />
      </PageHeader>
      <PageBody>
        <div className='flex flex-col md:flex-row items-start'>
          <div className='grid gap-y-6 md:w-3/4'>
            <PageSection title={t('group-health.setup.title')}>
              <FeinsWithoutBenefitsPanel />
            </PageSection>
            <PageSection title={t('group-health.benefits.title')}></PageSection>
          </div>
          <HelpSection />
        </div>
      </PageBody>
    </Page>
  )
}

type PageSectionProps = {
  title: string
}

const PageSection: React.FunctionComponent<
  React.PropsWithChildren<PageSectionProps>
> = ({ title, children }) => {
  return (
    <div className='grid gap-y-6'>
      <header className='font-semibold type-default'>{title}</header>
      {children}
    </div>
  )
}
