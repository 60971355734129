import * as React from 'react'
import { PayrollInstructionStepModal } from '@toasttab/buffet-pui-payroll-add-deduction-modal'
import { t } from '@local/translations'
import { StepProps } from '@toasttab/buffet-pui-payroll-add-deduction-modal/dist/types/PayrollInstructionStepModal/PayrollInstructionStepModal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DownloadIcon } from '@toasttab/buffet-pui-icons'
import { trackDeduction, useTracking } from '@local/track'

export type DeductionModalProps = {
  onRequestClose: VoidFunction
}

export const DeductionModal: React.FunctionComponent<DeductionModalProps> = ({
  onRequestClose
}) => {
  const steps: StepProps[] = [
    {
      title: t('deduction.step1.title'),
      description: t('deduction.step1.description'),
      button: (
        <Button
          {...trackDeduction('download')}
          as='a'
          href='https://d2c9w5yn32a2ju.cloudfront.net/knowledgebase/payroll/Deduction-Code-Request-Form_TPTM.pdf'
          iconRight={<DownloadIcon accessibility='decorative' />}
          rel='noreferrer'
          target='_blank'
          variant='text-link'
        >
          {t('deduction.step1.button')}
        </Button>
      )
    },
    {
      title: t('deduction.step2.title'),
      description: t('deduction.step2.description')
    },
    {
      title: t('deduction.step3.title'),
      description: t('deduction.step3.description'),
      button: (
        <Button
          {...trackDeduction('chat')}
          as='a'
          id='payroll_chatbot'
          variant='secondary'
        >
          {t('deduction.step3.button')}
        </Button>
      )
    }
  ]

  const { track } = useTracking()
  const onClose = () => {
    track('employee-benefits-spa.deduction.close')
    onRequestClose()
  }

  return (
    <PayrollInstructionStepModal
      title={t('deduction.title')}
      description={t('deduction.description')}
      isOpen={true}
      onClose={onClose}
      steps={steps}
    />
  )
}
