import * as React from 'react'
import { PayrollDashboardDisclosureCard } from '@toasttab/buffet-pui-payroll-dashboard-disclosure-card'
import { CommitmentAccountabilityIllustration } from '@toasttab/buffet-pui-illustrations'
import { t } from '@local/translations'
import {
  ConsentModal,
  InsuranceFlow
} from '../../third-part-data-sharing-consent-modal'

/**
 * GroupHealthBenefits component
 */
export const FeinsWithoutBenefitsPanel: React.FunctionComponent = () => {
  const [consentModalState, setConsentModalState] = React.useState<
    InsuranceFlow | undefined
  >(undefined)

  const openBrokerOfRecordConsentModal = () =>
    setConsentModalState('broker-of-record')

  const openQuoteConsentModal = () => setConsentModalState('quote')
  const closeInsuranceBrokerConsentModal = () => setConsentModalState(undefined)

  return (
    <>
      {consentModalState && (
        <ConsentModal
          insuranceFlow={consentModalState}
          onRequestClose={closeInsuranceBrokerConsentModal}
        />
      )}
      <PayrollDashboardDisclosureCard
        illustration={
          <CommitmentAccountabilityIllustration
            resizeToContainer={true}
            className='h-40'
          />
        }
        title={t('group-health.setup.panel.title')}
        description={t('group-health.setup.panel.description')}
        primaryAction={{
          label: t('group-health.setup.panel.button.quote'),
          onClick: openQuoteConsentModal
        }}
        secondaryAction={{
          label: t('group-health.setup.panel.button.connect'),
          onClick: openBrokerOfRecordConsentModal
        }}
        disclosureProps={{
          ariaLabelOpen: t(
            'group-health.setup.panel.disclosure-aria-label-open'
          ),
          ariaLabelClose: t(
            'group-health.setup.panel.disclosure-aria-label-close'
          ),
          iconLabel: '3',
          label: t('group-health.setup.panel.disclosure-label')
        }}
      />
    </>
  )
}
